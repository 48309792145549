<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="pb-0">
        <h3 class="font-weight-medium secondary--text"></h3>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          class="primarygrad white--text"
          @click="$router.push({ path: '/organization' })"
        >
          <v-icon left>mdi-clock-outline</v-icon>
          Set Office Timing
        </v-btn>
      </v-card-title>
      <v-card-title>
        <v-autocomplete
          v-model="selectedUser"
          :items="allUsers"
          item-text="name"
          item-value="id"
          label="Members"
          class="ma-2"
          hide-details="true"
          solo-inverted
          flat
          dense
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.name }}</span>

            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedUser.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRange"
              label="Date Range"
              prepend-inner-icon="event"
              hide-details="false"
              solo-inverted
              flat
              dense
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" color="primarygrad" scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(dates)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-dialog>
        <v-btn color="primarygrad white--text" class="mx-2" @click="load">
          <v-icon color="white">mdi-magnify</v-icon>
        </v-btn>
        <v-btn color="primarygrad" @click="exportReport">
          <v-icon color="white">cloud_download</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>

    <v-card flat style="margin-top: 15px; padding: 15px 0px 0px 0px">
      <v-card-text v-if="loading">
        <v-row
          class="fill-height"
          align-content="center"
          justify="center"
          style="margin: 0px"
        >
          <v-col class="subtitle-1 text-center" cols="12">
            {{ loadingText }}
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="primary accent-4"
              indeterminate
              rounded
              height="4"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>

      <v-simple-table
        v-else-if="!loading && reports.length > 0"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Members</th>
              <th
                v-for="(item, i) in rangeOfDates"
                :key="i"
                class="text-center"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in reports" :key="i">
              <td class="py-2 text-center">
                <v-avatar class="" color="primarygrad" size="32">
                  <v-img
                    v-if="item.pictureId"
                    :src="getImage(item.picture)"
                    alt=""
                  ></v-img>
                  <span v-else class="white--text headline">
                    {{ item.name.substring(0, 1).toUpperCase() }}
                  </span>
                </v-avatar>
                <br />
                <h3 class="font-weight-regular">{{ item.name }}</h3>
              </td>
              <td v-for="(cell, index) in item.data" :key="index" class="py-2">
                <v-card
                  elevation="0"
                  class="secondarygrad rounded py-2  align-center"
                  style="min-width:91px"
                >
                  <v-card-text>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div align="center" v-bind="attrs" v-on="on">
                          <v-icon
                            small
                            left
                            :color="cell.count > 0 ? 'primary' : 'red'"
                          >
                            {{
                              cell.count > 0
                                ? "record_voice_over"
                                : "voice_over_off"
                            }}
                          </v-icon>
                          {{ cell.count > 0 ? "On Duty" : "Off Duty" }}
                        </div>
                      </template>
                      <span>{{ cell.count > 0 ? "On Duty" : "Off Duty" }}</span>
                    </v-tooltip>
                    <v-divider class="mx-2"></v-divider>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div align="center" v-bind="attrs" v-on="on">
                          <v-icon small left color="primary">
                            phone_in_talk
                          </v-icon>
                          {{ `${cell.count}` }}
                        </div>
                      </template>
                      <span>Total Calls</span>
                    </v-tooltip>

                    <v-divider class="mx-2"></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div align="center" v-bind="attrs" v-on="on">
                          <v-icon small left color="primary">
                            access_time
                          </v-icon>
                          {{ `${timeFormatCustom(cell.timeSpentOnCall)}` }}
                        </div>
                      </template>
                      <span>Active Time</span>
                    </v-tooltip>
                    <v-divider class="mx-2"></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div align="center" v-bind="attrs" v-on="on">
                          <v-icon small left color="primary">
                            timer_off
                          </v-icon>
                          {{
                            `${timeFormatCustom(
                              officeTime - cell.timeSpentOnCall
                            )} mins`
                          }}
                        </div>
                      </template>
                      <span>Idle Time</span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-text v-else style="padding: 0px">
        <v-container
          fill-height
          fill-width
          style="text-align: center; width: 80%; display: inline"
        >
          <div style="text-align: center" align="center">
            <div align="center">
              <v-img
                :src="summary"
                style="width: 220px; justify-content: center; opactiy: 0.6"
              ></v-img>
            </div>
            <h3 class="h3">Nothings in Attendance</h3>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions style="text-align: center !important; padding: 0px">
        <v-spacer></v-spacer>
        <div class="v-data-footer">
          <div class="v-data-footer__select">
            Rows per page:
            <div
              class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
            >
              <v-select
                v-model="records"
                :items="recordsPerPage"
                style="margin: 5px"
                @change="load(records, 0)"
              ></v-select>
            </div>
          </div>
          <div class="v-data-footer__icons-before">
            <v-btn
              :disabled="page == 1 ? true : false"
              color="primary"
              fab
              small
              text
              @click="prevSet"
            >
              <v-icon :size="24">arrow_left</v-icon>
            </v-btn>
          </div>
          <div class="v-data-footer__pagination">
            {{ `${page}/${pageCount} of ${totalRecords}` }}
          </div>
          <div class="v-data-footer__icons-after">
            <v-btn
              :disabled="page >= pageCount ? true : false"
              color="primary"
              fab
              small
              text
              @click="nextSet"
            >
              <v-icon :size="24">arrow_right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar" multi-line :color="color">
      <h3 style="font-weight: 400; font-size: 13px; text-transform: uppercase">
        {{ snackbarText }}
      </h3>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
const _ = require("lodash");
import Papa from "papaparse";
import Blob from "blob";
import fb from "../js/firebase";

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      color: "primary",
      search: "",
      rangeOfDates: [],
      modal: false,
      dates: [],

      selectedUser: [],
      allUsers: [],
      selectedDispos: [],
      showTable: false,
      contactDetails: [],
      records: 5,
      loading: false,
      loadingText: "",
      reports: [],
      recordsPerPage: [5, 10, 20, 30],
      page: 1,
      pageCount: 0,
      totalRecords: 0,
      officeTime: null,
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "fieldList", "teams", "selectedTeam"]),
    summary() {
      return require("../assets/summary.png");
    },
    dateRange() {
      return this.dates
        .map((date) => moment(date).format("DD-MM-YYYY"))
        .join(" - ");
    },
    calcDispo() {
      return _.without(
        _.map(this.dispos, (d) => {
          if (
            d.title == "SMS" ||
            d.title == "LINK" ||
            d.title == "EMAIL" ||
            d.title == "NEW"
          ) {
            //do not pass these values
          } else {
            return d;
          }
        }),
        undefined
      );
    },
  },
  watch: {
    async selectedTeam() {
      try {
        await this.fetchAccounts(); // get accounts
        this.load(); // get the logs by day wise format
      } catch (e) {
        (this.snackbar = false), (this.snackbarText = "");
        this.color = "#c62828";
        return;
      }
    },
  },
  async created() {
    this.dates = [
      moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
    await this.fetchAccounts();
    await this.load();
    this.officeTime = this.$moment(
      this.$store.state.companyDetails.officeEndTime,
      "HH:mm"
    ).diff(
      this.$moment(this.$store.state.companyDetails.officeStartTime, "HH:mm")
    );
    this.officeTime = this.$moment.duration(this.officeTime).asMinutes();
  },

  methods: {
    timeFormatCustom(valueInMinutes) {
      if (valueInMinutes === 0) {
        return "0 sec";
      }

      var totalSeconds = valueInMinutes * 60;
      var days = Math.floor(totalSeconds / 86400);
      totalSeconds %= 86400;
      var hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      var minutes = Math.floor(totalSeconds / 60);
      var seconds = Math.floor(totalSeconds % 60);

      var result = "";
      if (days > 0) {
        result += days + (days === 1 ? " day " : " days ");
      }
      if (hours > 0) {
        result += hours + (hours === 1 ? " hr " : " hrs ");
      }
      if (minutes > 0) {
        result += minutes + (minutes === 1 ? " min " : " mins ");
      }
      if (seconds > 0) {
        result += seconds + (seconds === 1 ? " sec" : " secs");
      }

      return result.trim();
    },
    /** PAGINATION */
    nextSet() {
      this.page++;
      this.load(
        this.records * this.page,
        this.records * this.page - this.records
      );
    },
    prevSet() {
      this.page--;
      this.load(
        this.records * this.page,
        this.records * this.page - this.records
      );
    },
    // Fetch Accounts
    async fetchAccounts() {
      this.allUsers = [];

      try {
        var teams = [];
        teams = await this.getTeamIds();

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );
        this.allUsers = response.body;
        if (this.user.role == "Manager" || this.user.role == "Teammanager") {
          this.allUsers.unshift({
            id: "allMembers",
            name: "All",
          });
        }
        if (this.selectedUser.length < 1) {
          this.selectedUser.push(this.allUsers[0].id);
        }
      } catch (e) {
        if (e.body.error && e.body.error.message)
          return this.$swal({ type: "error", text: e.body.error.message });
        else
          return this.$swal({
            type: "error",
            text: "Error while fetching accounts",
          });
      }
    },

    /** Get array of teams, user ids */
    getTeamIds() {
      var ids = [];

      if (this.selectedTeam == "allTeams") {
        ids = _.map(this.teams, "id");
      } else {
        ids.push(this.selectedTeam);
      }

      ids = _.without(ids, "allTeams");
      return ids;
    },
    getUserIds(limit, skip) {
      var users = [];

      if (_.includes(this.selectedUser, "allMembers")) {
        users = _.map(this.allUsers, "id");
      } else {
        users = this.selectedUser;
      }
      users = _.without(users, "allMembers");

      if (limit == undefined || skip == undefined) {
        (limit = this.records), (skip = 0);
      }

      this.totalRecords = users.length;
      // total pages
      this.pageCount = Math.ceil(this.totalRecords / this.records);
      if (this.page > this.pageCount) {
        this.page = 1; //set default
      }
      // for member wise paginate
      users = users.slice(skip, limit);
      return users;
    },

    // Load dates and reports
    async load(limit, skip) {
      try {
        // Dates Range
        this.loading = true;
        this.loadingText = "Please wait, we are processing your data";
        var dates = [];

        var start = moment(this.dates[0]);
        var end = this.dates[1] == undefined ? start : moment(this.dates[1]);
        if (start > end) {
          var temp = start;
          start = end;
          end = temp;
        }
        var diff = end.diff(start, "days") + 1;

        // if range is greater than 30 days
        if (diff > 31) {
          this.snackbar = true;
          this.snackbarText = "Please select a range of 30 days";
          this.color = "#cc9900";
          return;
        }
        for (let i = 0; i < diff; i++) {
          dates.push(
            moment(start)
              .add(i, "days")
              .format("DD-MM-YYYY")
          );
        }

        this.reports = [];
        var users;
        var search = "";

        //Get users
        users = await this.getUserIds(limit, skip);

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/logs/v2/filterByDays`,
          {
            users: users,
            dates: this.dates,
          }
        );

        response = response.body;

        console.log("RESSSSS", JSON.stringify(response));

        var members = _.uniq(
          _.map(response, (r) => {
            return r._id.accountsId;
          })
        );

        console.log("members 1", JSON.stringify(members));

        members = _.map(users, (u) => {
          var userExists = _.find(members, u);
          if (userExists) {
            return userExists;
          } else {
            return u;
          }
        });

        console.log("members 2", JSON.stringify(members));

        console.log("dates", dates);

        var eachMembersData = _.map(members, (m) => {
          var user = this.getUserById(m);
          return {
            name: user.name,
            email: user.email,
            picture: user.picture,
            pictureId: user.pictureId,
            data: _.map(dates, (d) => {
              var dx = _.find(response, (r) => {
                return (
                  r._id.accountsId == m &&
                  moment(r._id.day).format("DD-MM-YYYY") == d
                );
              });
              if (dx) {
                return {
                  count: dx.count,
                  duration: (Number(dx.duration) / 1000 / 60).toFixed(1),
                  timeSpentOnCall: (
                    Number(dx.timeSpentOnCall) /
                    1000 /
                    60
                  ).toFixed(1),
                };
              } else {
                return { count: 0, duration: 0, timeSpentOnCall: 0 };
              }
            }),
          };
        });
        this.reports = eachMembersData;

        console.log("this.reports", JSON.stringify(this.reports));

        this.rangeOfDates = dates;

        this.loading = false;
        this.loadingText = "";
      } catch (e) {
        console.log("Error", e);
        this.loading = false;
        this.loadingText = "Error while loading your data";
      }
    },
    getUserById(id) {
      for (var u in this.allUsers) {
        if (this.allUsers[u].id == id) {
          return this.allUsers[u];
        }
      }
    },

    // Export CSV
    exportReport() {
      try {
        const self = this;
        var exportData = self.reports;
        self.loader = true;
        exportData = _.map(exportData, (d) => {
          var i = 0;
          var payload = [];
          while (i < 3) {
            var y = {
              Name: d.name.toString(),
              Email: d.email || "",
            };
            // Ensure date headers are formatted correctly
            for (var x in self.rangeOfDates) {
              const formattedDate = moment(
                self.rangeOfDates[x],
                "DD-MM-YYYY"
              ).format("DD-MM-YYYY");

              if (i == 0) {
                y.Type = "Call";
                y[formattedDate] = d.data[x].count;
              } else if (i == 1) {
                y.Type = "Duration (M)";
                y[formattedDate] = d.data[x].duration;
              } else {
                y.Type = "TimeSpend (M)";
                y[formattedDate] = d.data[x].timeSpentOnCall;
              }
            }
            i++;
            payload.push(y);
          }
          return payload;
        });
        exportData = _.flattenDepth(exportData);
        if (exportData.length < 1) {
          self.loader = false;
          self.snackbar = true;
          self.snackbarText = "No records to export";
          self.color = "#cc9900";
          return;
        }
        var csv = Papa.unparse(exportData);
        var csvData = new Blob([csv], {
          type: "text/csv;charset=utf-8;",
        });
        var csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(
            csvData,
            `report_${new Date().getTime()}.csv`
          );
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `report_${new Date().getTime()}.csv`);
        tempLink.click();

        self.loader = false;
      } catch (e) {
        console.log(e);
        self.loader = false;
        self.snackbar = true;
        self.snackbarText = "Something went wrong while exporting reports.";
        self.color = "error";
      }
    },

    getImage(pic) {
      return `${this.$store.state.ENDPOINT}/storages/get/${pic}?access_token=${this.$store.getters.user.token}`;
    },
  },
};
</script>

<style scoped>
.h3 {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
</style>
